import React from "react"
// import PropTypes from "prop-types"
import classNames from "classnames"
import styled from "styled-components"
import Link from "../Link"
import tw from "tailwind.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"

const BreadcrumbSection = styled.div`
  background-color: #ededed;

  .breadcrumb-inner-wrap {
    ${tw`max-w-xl mx-auto px-18 md:px-40 lg:px-60 xxl:px-0 py-6 xl:py-8`}
  }

  .breadcrumb-content {
    ${tw`flex items-center`}

    .breadcrumb-item {
      ${tw`items-center`}

      a {
        ${tw`leading-5 font-medium text-xs uppercase text-secondary flex items-center`}
        letter-spacing: 0.6px;
        margin-left: 10px;

        :hover {
          ${tw`text-black-dark`}
        }
      }

      svg {
        max-height: 15px;
        margin-left: 10px;
      }

      :first-child {
        a {
          ${tw`md:ml-0`}
        }
      }

      :last-child {
        svg {
          ${tw`md:hidden ml-0`}

        }

        a {
            margin-left: 10px;
        }
      }

      p {
        ${tw`leading-5 font-medium text-xs uppercase mb-0 text-black-dark`}
        margin-left: 10px;
        letter-spacing: 0.6px;
      }
    }
  }
`

const Breadcrumb = ({ pages, noViewAll = false }) => {
  return (
    <BreadcrumbSection>
      <div className="breadcrumb-inner-wrap">
        <div className="breadcrumb-content">
          {pages.map((page, index) => (
            <div
              key={index}
              className={classNames("breadcrumb-item", {
                "hidden md:flex": !page.visibleInMobile,
                "flex mobile-enable": page.visibleInMobile

              })}
            >
              <FontAwesomeIcon icon={faAngleLeft} className="flex md:hidden" />
              {page.url ? (
                <Link to={page.url}>
                    {!noViewAll && (<span className="flex md:hidden">View all &nbsp; </span>)}
                  {page.title}
                </Link>
              ) : (
                <p>
                  {!noViewAll && (<span className="flex md:hidden">View all &nbsp; </span>)}
                  {page.title}
                </p>
              )}
              <FontAwesomeIcon icon={faAngleRight} className="hidden md:flex" />
            </div>
          ))}
        </div>
      </div>
    </BreadcrumbSection>
  )
}

export default Breadcrumb
