import React, { useRef, useEffect, useState } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import classNames from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import SEO from "components/seo"
import styled from "styled-components"
import tw from "tailwind.macro"
import Flickity from "react-flickity-component"
import Link from "../components/Link"
import Layout from "../components/layout"
import Form from "../components/section-form"
import Breadcrumb from "../components/Breadcrumb"

export const query = graphql`
  query productDetail($id: String!) {
    product: allWpProduct(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        acfProducts {
          description: productDescription
          options: productOption {
            package: productPackage
            price: productPrice
            size: productSize
            weight: productWeight
          }
          images: productImages {
            id
            localFile {
              childImageSharp {
                fixed(width: 325, height: 325, quality: 90) {
                  src
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        ...ProductSEO
      }
    }
  }
`

const ProductDetailSection = styled.section`
  ${tw`max-w-xl mx-auto px-18 md:px-40`}

  .product-detail-inner {
    ${tw`w-full mx-auto`}

    @media (min-width: 768px) {
      max-width: 400px;
    }

    @media (min-width: 1280px) {
      max-width: 100%;
      ${tw`px-18 flex`}
    }
  }

  .content-col {
    ${tw`mt-46 font-medium md:px-5 md:mt-50 xl:w-1/2 xl:mt-0 xl:pl-12 xl:pr-0`}

    > h2 {
      ${tw`font-fb text-2xl text-black-dark leading-8`}
    }

    p {
      ${tw`leading-7 font-medium mt-7`}
    }

    strong {
      ${tw`font-fb`}
    }

    ul {
      ${tw`pl-15`}

      li {
        ${tw`list-disc`}
      }
    }

    li {
      ${tw`mb-5`}
    }

    table {
      ${tw`w-full table-fixed mt-16 mb-6 xl:mb-10`}

      thead {
        border-bottom: 5px solid white;
      }

      tbody {
        ${tw`block overflow-auto`}

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
          height: 10px;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #bcbcbc;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }

      th {
        ${tw`font-fb text-left`}
      }

      td {
        ${tw`font-medium text-center p-3`}
        border: 1px solid #000;
      }
    }
  }

  .product-image-slider {
    ${tw`relative xl:w-1/2 xl:mt-3`}

      .gatsby-image-wrapper {
      /* height: auto !important;
      padding-bottom: 100% !important; */
      width: 244px !important;
      height: 244px !important;
      max-height: 325px;
      padding: 0 15px;
      border-radius: 60px 0;
      transition: opacity 300ms ease-in;
      ${tw`object-cover opacity-0`}

      @media (min-width: 768px) {
        width: 386px !important;
        height: 325px !important;
      }

      @media (min-width: 1280px) {
        width: 325px !important;
        height: 325px !important;
      }

      &.is-selected {
        ${tw`opacity-100`}
      }
    }
  }

  .carousel {
    ${tw`outline-none mx-auto relative`}
    max-width: 385px;

    @media (min-width: 1280px) {
      max-width: 350px;
    }

    &.one-slide {
      .flickity-button {
        ${tw`hidden`}
      }
    }
  }


  .flickity-viewport {
    ${tw`overflow-hidden h-full`}
  }

  .flickity-button {
    ${tw`outline-none absolute`}
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    svg {
      height: 25px;
      max-width: 25px;
    }

    &.previous {
      left: -20px;

      @media (min-width: 768px) {
        left: -80px;
      }
    }

    &.next {
      right: -20px;

      @media (min-width: 768px) {
        right: -80px;
      }
    }
  }
`

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  cellSelector: ".slide",
  accessibility: true,
  prevNextButtons: true,
  pageDots: false,
}

const DynamicBreadCrumb = ({ data }) => {
  const [breadcrumbData, setBreadcrumbData] = useState(null)
  useEffect(() => {
    const grapqlURL = `${process.env.SOURCE_URL}/graphql`

    const fetchData = async () => {
      const { data: breadcrumbContent } = await axios({
        url: grapqlURL,
        method: "post",
        data: {
          query: `
					query {
						product(id: "${data.id}", idType: ID) {
							title
							productCategories {
								nodes {
									slug
									name
									parent {
										node {
											slug
											name
										}
									}
								}
              }
						}
					}
					`,
        },
      })
      setBreadcrumbData(breadcrumbContent.data)
    }
    fetchData()
  }, [data.id])

  if (typeof window !== `undefined`) {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 300)
  }

  const getProductParentCategoryUrl = data => {
    return data.parent?.node
      ? `/products/${data.parent?.node?.slug}/${data.slug}`
      : `/products/${data.slug}`
  }

  if (breadcrumbData) {
    const breadcrumbsData = [
      {
        title: "All Products",
        url: "/products",
        visibleInMobile: false,
      },
      {
        title: breadcrumbData?.product?.productCategories?.nodes[0]?.name,
        url: getProductParentCategoryUrl(
          breadcrumbData?.product?.productCategories?.nodes[0]
        ),
        visibleInMobile: true,
      },
      {
        title: breadcrumbData?.product?.title,
        url: "",
        visibleInMobile: false,
      },
    ]

    if (breadcrumbData?.product?.productCategories?.nodes[0]?.parent) {
      const item = {
        title:
          breadcrumbData?.product?.productCategories?.nodes[0]?.parent?.node
            ?.name,
        url: `/products/${breadcrumbData?.product?.productCategories?.nodes[0]?.parent?.node?.slug}`,
        visibleInMobile: false,
      }
      breadcrumbsData.splice(1, 0, item)
    }
    return <Breadcrumb pages={breadcrumbsData} />
  } else {
    return null
  }
}

const ProductDetailPage = ({ data, location, pageContext }) => {
  let flickityRef = useRef(null)

  useEffect(() => {
    flickityRef.current.on("ready", () => {
      flickityRef.current.resize()
    })
  }, [])

  const product = data.product.nodes[0]
  return (
    <Layout path={location.pathname}>
      <SEO data={product?.seo} />
      {/* <SubBanner mainTitle={product.title} /> */}

      <DynamicBreadCrumb data={product} />

      <ProductDetailSection className="section-gap-sm xl:pt-73">
        <div className="product-detail-inner">
          <div className="product-image-slider">
            <Flickity
              className={classNames("carousel", {
                "one-slide": product.acfProducts.images.length === 1,
              })}
              options={flickityOptions}
              flickityRef={c => (flickityRef.current = c)}
            >
              {product.acfProducts.images.map(image => (
                <Img
                  className="slide"
                  key={image.id}
                  fixed={image.localFile.childImageSharp.fixed}
                  alt={product.title}
                />
              ))}
            </Flickity>
          </div>
          <div className="content-col">
            <h2>{product.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: product.acfProducts.description,
              }}
            ></div>
            {/* <table>
              <thead>
                <tr>
                  <th>Weight</th>
                  <th>Size</th>
                  <th>Package</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {product.acfProducts.options.map((option, index) => (
                  <tr key={index}>
                    <td>{option.weight}</td>
                    <td>{option.size}</td>
                    <td>{option.package}</td>
                    <td>{option.price}</td>
                  </tr>
                ))}
              </tbody>
            </table> */}
            <Link
              className="btn-outline-rounded mt-18 md:mt-32"
              to="/contact-us"
            >
              Request a Quote
            </Link>
          </div>
        </div>
      </ProductDetailSection>
      <Form />
    </Layout>
  )
}

export default ProductDetailPage
